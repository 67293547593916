import "../htmx"
import htmx from "htmx.org"
import "htmx.org/dist/ext/response-targets"
import applicationVersionFileUploader, { ApplicationVersionFileUploader } from "./modules/applicationVersion"
import recaptcha, { Module as Recaptcha } from "./modules/recaptcha"

declare global {
  interface Window {
    htmx: typeof htmx
    onFileInputChange: (event: Event) => void
    togglePasswordInput: (event: MouseEvent) => void
    toggleAccordion: (event: MouseEvent) => void
    closeModal: (eventOrId: MouseEvent | string, remove?: boolean) => void
    closeModalIfSuccess: (event: CustomEvent, id: string) => void
    closeAlert: (eventOrId: MouseEvent | string) => void
    copyToClipboard: (eventOrValue: MouseEvent | string) => Promise<void>
    toggleCopyIcon: (element: HTMLElement, timeout?: number) => void
    // dropdown
    closeDropdown: (dropdownId: string) => void
    closeDropdowns: (container?: HTMLElement, exclude?: string) => void
    onDropdownResize: () => void
    openDropdown: (dropdownId: string, toggleElement?: HTMLElement) => void
    toggleDropdown: (event: MouseEvent, dropdownId: string) => void
    // loader
    hideLoader: () => void
    // application version
    applicationVersionFileUploader: ApplicationVersionFileUploader
    downloadFile: (event: CustomEvent, button: HTMLButtonElement) => void
    sendAssetValue: (params: { id: string, label: string, value: string }) => void
    hideSelect: (event: CustomEvent, selectId: string) => void
    // recaptcha
    recaptcha: Recaptcha,
    RECAPTCHA_SITE_KEY: string,
    // forms
    formValues: {
      [formId: string]: {
        [key: string]: number | string
      }
    }
    initForms: () => void
    registerForm: (formId: string) => void
    registerFormControl: (formId: string, inputName: string) => void
    onFormUpdate: (formOrId: HTMLFormElement | string) => void
    resetForm: (formOrId: HTMLFormElement | string) => void
    clearInputError: (elementOrSelector: HTMLInputElement | string) => void
    onFormBeforeRequest: (elementOrSelector: HTMLFormElement | string) => void
    onFormAfterRequest: (elementOrSelector: HTMLFormElement | string) => void
    enableFormSubmit: () => void
    // licenses
    licenseTemplateUploader: (event: CustomEvent, form: HTMLFormElement) => void
    onLicenseResOrFunCheck: (event: Event) => void
    onLicenseResOrFunCheckEdit: (event: Event) => void
    fillLicenseResOrFunCheckboxes: () => void
    onLicenseSemverInputChange: (input: HTMLInputElement) => void
    downloadLicenseTemplate: (event: CustomEvent) => void
    downloadReportAsJSON: (event: CustomEvent, filename: string) => void
    checkLicenseModalInputs: () => void
    // semver
    validateSemverInput: (input: HTMLInputElement) => void
    validateSemverString: (value: string) => boolean
    // resource version
    resourceVersionFileUploader: ResourceVersionFileUploader
    // asset version
    assetVersionFileUploader: AssetVersionFileUploader
    // toast
    showToast: (message: string, type: ToastType) => void
    hideLitepickers: () => void
    entityModalNameInput: (event: InputEvent, submitButtonId: string) => void
    captchaExplicitRender: (container: string | HTMLElement, options: { action: string }) => Promise<string>
  }
  interface HTMLInputElement {
    hasFormListener?: boolean
  }
  interface HTMLSelectElement {
    hasFormListener?: boolean
  }
  interface HTMLTextAreaElement {
    hasFormListener?: boolean
  }
}

// https://github.com/bigskysoftware/htmx/issues/2587
htmx.config.historyCacheSize = 0
htmx.config.scrollIntoViewOnBoost = false
htmx.config.defaultSwapStyle = "outerHTML"

import "./modules/dropdown"
import "./modules/form"
import "./modules/licenses"
import { loader } from "./modules/loader"
import "./modules/semver"
import resourceVersionFileUploader, { ResourceVersionFileUploader } from "./modules/resourceVersion"
import assetVersionFileUploader, { AssetVersionFileUploader } from "./modules/assetVersion"
import { BUTTON_DEBOUNCE } from "./modules/form"
import { initTooltips } from "src/client/scripts/modules/tooltip"
import { ToastType, createToast } from "src/client/scripts/modules/toast"
import { initAllDatepickers } from "src/client/scripts/modules/datepickers"
import { initTextEllipsis } from "src/client/scripts/modules/text-ellipsis"
import * as SelectSearch from "./modules/selectSearch"
import { initLicenseCustomerTransferModal, initLicenseImportForm, initLicenseSetupForm } from "./modules/licenses"

htmx.onLoad(e => {
  SelectSearch.htmxInit(e)

  initLicenseSetupForm(e)
  initLicenseImportForm(e)

  initLicenseCustomerTransferModal(e)
})


window.addEventListener("DOMContentLoaded", () => {
  let debounceTimeout: number
  let buttonLoadingTimeout: number
  initAllDatepickers()
  initTooltips()
  initTextEllipsis()
  window.checkLicenseModalInputs()

  window.addEventListener("resize", () => {
    initTextEllipsis()
    clearTimeout(debounceTimeout)
    debounceTimeout = window.setTimeout(() => {
      window.onDropdownResize()
    }, BUTTON_DEBOUNCE)
  })

  window.addEventListener("htmx:afterSwap", () => {
    initAllDatepickers()
    initTextEllipsis()
    initTooltips()
    window.checkLicenseModalInputs()
  })

  document.body.addEventListener("showSuccessToast", (event) => {
    const message = (event as CustomEvent).detail.value ?? "Operation completed successfully"
    createToast(message, "success")
  })

  document.body.addEventListener("showErrorToast", (event) => {
    const errorMessage = (event as CustomEvent).detail.value ?? "An unexpected error occurred, we're already working on it. Try again later or contact us"
    createToast(errorMessage, "error")
  })

  // Show warning toast on connection error
  document.body.addEventListener("htmx:sendError", () => {
    const errorMessage = "Connection error, please check your internet connection and try again"
    createToast(errorMessage, "warning")
  })

  document.body.addEventListener("click", () => {
    window.closeDropdowns()
  })

  document.body.addEventListener("htmx:historyRestore", () => {
    window.closeDropdowns()
  })

  document.body.addEventListener("htmx:beforeRequest", (event) => {
    const headers = (event as CustomEvent).detail.requestConfig.headers
    if (!headers["HX-Keep-Dropdown"]) {
      window.closeDropdowns()
    }
    if (headers["HX-Disable-Loader"] || headers["HX-Preload"]) {
      return
    }

    const isButton = ((event as CustomEvent).target as HTMLElement)?.tagName === "BUTTON"
    if (isButton) {
      buttonLoadingTimeout = window.setTimeout(() => {
        ((event as CustomEvent).target as HTMLElement)?.classList.add("btn--loading")
      }, BUTTON_DEBOUNCE)
    }

    loader.show()

    SelectSearch.onSelectSearchBeforeRequest(event)
  })

  document.body.addEventListener("htmx:afterRequest", (event) => {
    clearTimeout(buttonLoadingTimeout)
    const isButton = ((event as CustomEvent).target as HTMLElement)?.tagName === "BUTTON"
    if (isButton) {
      ((event as CustomEvent).target as HTMLElement)?.classList.remove("btn--loading")
    }
    loader.hide()

    SelectSearch.onSelectSearchAfterRequest(event)
  })

  window.initForms()
})

window.addEventListener("popstate", () => {
  setTimeout(() => {
    initTextEllipsis()
    initTooltips()
  }, 200)

  const id = setInterval(() => {
    if (loader.isReady()) {
      loader.hide()

      const buttons = document.querySelectorAll(".btn--loading")
      buttons.forEach((button) => {
        button.classList.remove("btn--loading")
      })

      clearInterval(id)
    }
  }, 20)
})

window.onFileInputChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  const files = target.files
  const label = target.nextElementSibling as HTMLLabelElement
  const labelText = label.querySelector(
    ".js-file-input-text"
  ) as HTMLSpanElement
  const labelValue = labelText.innerHTML
  let fileName: string | undefined

  if (files && files.length) {
    fileName = files[0].name
    if (fileName && fileName.length > 20) {
      fileName = `${fileName.slice(0, 20)}...${fileName.slice(
        fileName.length - 4
      )}`
    }
    labelText.innerHTML = fileName || labelValue
  }
}

window.togglePasswordInput = (e: MouseEvent) => {
  const parent = (e.target as HTMLDivElement).closest(".input__container")
  parent
    ?.querySelector("input")
    ?.setAttribute(
      "type",
      parent.querySelector("input")?.getAttribute("type") === "password"
        ? "text"
        : "password"
    )
  const tag = (e.currentTarget as HTMLDivElement).querySelector("svg use")
  tag?.setAttribute(
    "href",
    tag.getAttribute("href") === "#eye" ? "#eye-slash" : "#eye"
  )
}

window.toggleAccordion = (e: MouseEvent) => {
  const accordion = (e.target as HTMLDivElement).closest(".accordion")
  accordion?.classList.toggle("accordion--open")
}

window.closeModal = (eventOrId: MouseEvent | string, remove = true) => {
  let modal: HTMLElement | null = null
  if (typeof eventOrId === "string") {
    modal = document.getElementById(eventOrId)
  } else {
    modal = (eventOrId.target as HTMLElement).closest(".modal__wrap")
  }

  if (modal) {
    if (remove) {
      modal.remove()
    } else {
      modal.classList.remove("is-open")
    }
  }
}

/*
  Closes modals after htmx request success
  Must be called on afterRequest event
*/
window.closeModalIfSuccess = (event: CustomEvent, id: string, remove = true) => {
  if (event.detail.successful) {
    if (event.detail?.xhr?.status && event.detail.xhr.status !== 200) {
      return
    }
    window.closeModal(id, remove)
  }
}

window.closeAlert = (eventOrId: MouseEvent | string) => {
  let alert: HTMLElement | null = null
  if (typeof eventOrId === "string") {
    alert = document.getElementById(eventOrId)
  } else {
    alert = (eventOrId.target as HTMLElement).closest(".alert")
  }

  if (alert) {
    alert.remove()
  }
}

window.copyToClipboard = async (eventOrValue: MouseEvent | string, onSuccess?: (copiedValue: string) => void) => {
  let value: string
  if (typeof eventOrValue === "string") {
    value = eventOrValue
  } else {
    eventOrValue.preventDefault()
    eventOrValue.stopPropagation()
    const target = eventOrValue.target as HTMLElement
    value = target?.dataset?.["value"] ?? (
      target?.closest("[data-value]")?.getAttribute("data-value") ||
      target?.querySelector("[data-value]")?.getAttribute("data-value") ||
      ""
    )
  }
  try {
    await navigator.clipboard.writeText(value)
    onSuccess?.(value)
  } catch (err) {
    alert("Failed to copy text to clipboard")
  }
}

const TOGGLE_COPY_ICON_TIMEOUT = 2000
window.toggleCopyIcon = (element: HTMLElement, timeout = TOGGLE_COPY_ICON_TIMEOUT) => {
  const tag = element.tagName === "use" ? element : element.querySelector("use")
  if (tag) {
    tag.setAttribute("href", "#check")
    setTimeout(() => {
      tag?.setAttribute("href", "#file-copy")
    }, timeout)
  }
}

// loader
window.hideLoader = loader.hide

// application version
window.applicationVersionFileUploader = applicationVersionFileUploader

window.downloadFile = async (event) => {
  const { url, partialKey: filename } = JSON.parse(event.detail.xhr.response)
  const response = await fetch(url)
  const blob = await response.blob()
  const urlObject = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = urlObject
  link.download = filename + ".zip"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// recaptcha
window.recaptcha = recaptcha

// resources
window.resourceVersionFileUploader = resourceVersionFileUploader

// assets
window.assetVersionFileUploader = assetVersionFileUploader

// toast
window.showToast = createToast

// Organization reports
window.downloadReportAsJSON = async (event, filename) => {
  const response = JSON.stringify(JSON.parse(event.detail.xhr.response), null, 4)
  const blob: Blob = new Blob([response], { type: "application/json" })
  const urlObject = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = urlObject
  link.download = filename + ".json"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(urlObject)
}

// entity modal
window.entityModalNameInput = (event: InputEvent, submitButtonId: string) => {
  const submitButton = document.getElementById(submitButtonId)
  const value = (event.target as HTMLInputElement).value ?? ""
  if (value !== (event.target as HTMLInputElement).getAttribute("data-initial-value")) {
    submitButton?.removeAttribute("disabled")
    submitButton?.classList.remove("btn--disabled")
    window.clearInputError(event.target as HTMLInputElement)
  }
}

window.checkLicenseModalInputs = () => {
  const groups = document.querySelectorAll("[id^=\"license-template-json-\"][id$=\"-group\"]")
  if (groups.length === 1) {
    groups[0].querySelector("button")?.classList.add("!hidden")
  } else {
    groups.forEach((group) => {
      group.querySelector("button")?.classList.remove("!hidden")
    })
  }
}
