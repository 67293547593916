type Captcha = object & {
  execute: (container: string | HTMLElement, options: { action: string }) => Promise<string>
  render: (element: string | HTMLElement, options: { sitekey: string, theme: "light" | "dark" | "auto", callback: () => void }) => void
}

const captchaExplicitRender = (turnstile: Captcha, containerName: string, recaptchaKey: string) => {
  const container = document.getElementById(containerName)
  console.log(container)
  if (!container) {
    return
  }
    turnstile.render(container, {
      sitekey: recaptchaKey,
      theme: "light",
      callback: () => {
        window.enableFormSubmit()
      }
    })
}

export type Module = {
  captchaExplicitRender: (turnstile: Captcha, containerName: string, recaptchaKey: string) => void
}

const recaptcha: Module = {
  captchaExplicitRender
}

export default recaptcha
